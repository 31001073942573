import { isNullOrEmpty } from './common-helpers';
import { leadStr } from './string-helpers';

export function timeToSeconds(timeString?: string | null): number | undefined {
    if(isNullOrEmpty(timeString) || !/[0-9]*:?[0-9]{1,2}/ig.test(timeString)) return undefined;
    return timeString.split(':')
        .filter(p => p && p !== '')
        .reverse()
        .reduce((call: number, p: string, i: number) => call + (Number(p) * (60 ** i)), 0);
}

export function secondsToTimeString(seconds?: number, full = false): string | undefined {
    if(!seconds && seconds !== 0) return undefined;

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds - h * 3600) / 60);
    const s = Math.floor(seconds % 60);

    return (h ? leadStr(h, '0', 2) + ':' : (full ? '00:' : ''))
        + (h || m ? leadStr(m, '0', 2) + ':' : (full ? '00:' : ''))
        + leadStr(s, '0', 2);
}

export function calculateDaysFromCurrentDate(targetDate: any) {
  // Get today's date
  const today = new Date();

  // Convert dates to milliseconds
  const targetDateInMillis = targetDate.getTime();
  const todayInMillis = today.getTime();

  // Calculate the difference in milliseconds
  const differenceInMillis = targetDateInMillis - todayInMillis;

  // Convert milliseconds to days
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const daysDifference = Math.round(differenceInMillis / millisecondsPerDay);

  return daysDifference;
}