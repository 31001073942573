import { IUser } from '../models/user';
import {calculateDaysFromCurrentDate} from '../helpers/datetime-helpers';

export function getFreeTrialString(user: IUser) {
      let trialString = ""; 
      
      if(user?.userPlan?.trialActiveForUser && !user?.userPlan?.trialExpiredForUser) {
        if(user?.userPlan?.trialExpirationForUser) {
          const daysRemaining = calculateDaysFromCurrentDate(new Date(user?.userPlan?.trialExpirationForUser));
          trialString = "Free trial expires in " + daysRemaining + " days. ";
        }
        else trialString = "Free trial. ";
      } 
      else if (user?.userPlan?.trialActiveForUser && user?.userPlan?.trialExpiredForUser) trialString = "Free trial expired. ";

      return trialString;
}