/* eslint-disable no-undef */
import { getCurrentUser } from './api/actions/get-current-user';
import { openExtensionUrl } from './helpers/common-helpers';

export const getDaysInMonth = (month, year, isCurrentMonth) => {
  const date = new Date(year, month, 1);
  const days = [];
  const today = new Date();

  while (date.getMonth() === month) {
    let dateObj = {
      date: new Date(date).toISOString().split('T')[0],
      isCurrentMonth,
    };
    if (date.getDate() === today.getDate() && month === new Date().getMonth()) {
      // control for year here also
      dateObj['isToday'] = true;
    }
    days.push(dateObj);
    date.setDate(date.getDate() + 1);
  }

  const first = new Date(days[0].date).getDay();
  const last = days[days.length - 1].date;

  return { days, first, last };
};

export const hasMotivation = (usrMotive) => {
  if (!usrMotive || usrMotive.length === 0) return false;
  return usrMotive.some(
    (m) => m === 'educational_interest' || m === 'mental_health'
  );
};

export const hasPDMotivation = (usrMotive) => {
  if (!usrMotive || usrMotive.length === 0) return false;
  return usrMotive.some((m) => m === 'prof_development');
};

export const hasOtherMotivation = (usrMotive) => {
  if (!usrMotive || usrMotive.length === 0) return false;
  return usrMotive.some((m) => m === 'other');
};

export const hasPermissions = (userGroups) => {
  return (
    userGroups?.some(
      (userGroup) =>
        Array.isArray(userGroup.userPermissions) &&
        userGroup.userPermissions.length > 0
    ) ?? false
  );
};

export const formatDate = (inputDate, display = '') => {
  const date = new Date(inputDate);
  const month = (date.getUTCMonth() + 1).toString();
  const day = date.getUTCDate().toString();
  const year = date.getUTCFullYear().toString();
  if (display === 'month-day') return `${month}-${day}`;
  return `${month}-${day}-${year}`;
};

export const hasInstitutionalMotivation = (usrMotive) => {
  if (hasMotivation(usrMotive) || hasPDMotivation(usrMotive)) return true;

  if (!usrMotive || usrMotive.length === 0) return false;
  return usrMotive.some((m) => m === 'assignment');
};

export async function onSuccessLogin(
  configurationFinished,
  history,
  setUser,
  remainOnPage,
  user
) {
  try {
    if (!user) {
      user = await getCurrentUser();
    }

    if (user) {
      const requiresEmailComfirmation = user.userGroups?.some(
        (ug) =>
          ug.enabled &&
          ug.emailConfirmationRequired &&
          user.confirmedEmail !== user.email
      );

      if (requiresEmailComfirmation) {
        history.replace('/onboarding-confirm-email');
      } else {
        if (remainOnPage) {
          history.replace(remainOnPage);
        } else {
          history.replace('/analytics');
        }
      }
      setUser(user);
      return true;
    }
  } catch (error) {
    // eslint-disable-next-line no-undef
    console.error('Failed to fetch the current user:', error);
  }
}

export async function onSuccessSignUp(
  destination,
  history,
  setUser,
  source,
  user
) {
  const link = destination ? destination : '/analytics';
  history.replace(link);

  try {
    if (!user) {
      user = await getCurrentUser();
    }
    setUser(user);

    if ((!source || source !== 'ext') && link === '/analytics') {
      // Prompt user to install extension if they did not sign up via extension
      setTimeout(openExtensionUrl, 3000);
    }
  } catch (error) {
    console.error('Error fetching current user:', error);
    // Handle the error appropriately, e.g., show a notification or redirect
  }
}
