import React, { useEffect, useState, useContext } from 'react';
import { OrgFinder } from '../../components/org-finder/org-finder';
import { useCurrentUser } from '../../contexts/current-user-context';
import SearchFilter from '../search/search-filter';
import { ContentAgeRating } from '../../models/narrative';
import { DatePicker } from '../../components/datepicker/datepicker';
import { IUserGroup } from '../../models/user-group';
import { userGroupSave } from '../../api/actions/user-groups/user-group-save';
import { addUserToUserGroup } from '../../api/actions/user-group-user-add';
import { toast } from 'react-toastify';
import { basePerformError } from '../../helpers/error-helpers';
import { MultiError } from '../../types/multi-error';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDate } from '../../utils.js';
import { PopupContext } from '../../contexts/popup-context';
import useLocalStorage from '../../hooks/useLocalStorage';
import { TwPopup } from '../../components/popup/tw-popup';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { LinkButton } from '../../components/link-button/link-button';

interface State {
  title: string;
  gradeLevel: string;
  maxAgeRating: number;
  startDate: string;
  endDate: string;
  uid: string;
  parent: { uid: IUserGroup };
}

export const CreateClasses: React.FC = () => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const location = useLocation<State>();
  const [selectedOption, setSelectedOption] = useState('grade');
  const [selectedDevelopmentLevel, setSelectedDevelopmentLevel] =
    useState<string>('');
  const [selectedOrg, setSelectedOrg] = React.useState<string>('');
  const [ageRating, setAgeRating] = useState<number>();
  const [className, setClassName] = useState('');
  const [startAndEndDates, setStartAndEndDates] = useState({
    start: '',
    end: '',
  });
  const [selectedOrgUid, setSelectedOrgUid] = useState<string | undefined>(
    undefined
  );
  const [isEditing, setIsEditing] = useState(false);
  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { setOpenPopup } = popupContext;
  const [hasSeenPopup, setHasSeenPopup] = useLocalStorage(
    'orientation-popup',
    false,
    currentUser?.uid
  );
  const orgTypeOrganizationCount = currentUser?.userGroups?.filter(
    (ug) => ug.groupType === 'organization'
  ).length;

  const grades = [
    'Pre-K',
    'K',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    'Undergraduate',
    'Graduate',
  ];

  const gradeObject: Record<string, string> = grades.reduce((obj, grade) => {
    obj[grade] = grade;
    return obj;
  }, {} as Record<string, string>);

  const handleRadioChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOption(event.target.value);
    setSelectedDevelopmentLevel('');
  };

  const handleAgeFiltering = (age: any) => {
    age === '999' ? (age = undefined) : null;
    setAgeRating(age);
  };

  const handleSelectOptionDisplay = (option: string) => {
    setSelectedDevelopmentLevel(option);
  };

  const handleStartAndEndDates = (date: string, dateType: string) => {
    if (
      dateType === 'end' &&
      new Date(date) < new Date(startAndEndDates.start)
    ) {
      toast.error('Error: End date cannot be earlier than start date');
      return;
    }

    if (
      dateType === 'start' &&
      new Date(date) > new Date(startAndEndDates.end) &&
      startAndEndDates.end !== ''
    ) {
      toast.error('Error: Start date cannot be later than end date');
      return;
    }

    setStartAndEndDates({
      ...startAndEndDates,
      [dateType]: date,
    });
  };

  const handleAddUser = async (orgUid: string, userUid: string | undefined) => {
    try {
      if (orgUid && userUid) {
        const res = await addUserToUserGroup(orgUid, userUid);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateClass = async () => {
    const newClass: Partial<IUserGroup> = {
      uid: location.state?.uid || undefined,
      title: className,
      groupType: 'user',
    };

    if (startAndEndDates.start.length > 0) {
      newClass.startDate = new Date(startAndEndDates.start).toISOString();
    }
    if (startAndEndDates.end.length > 0) {
      newClass.endDate = new Date(startAndEndDates.end).toISOString();
    }

    if (!isEditing) {
      newClass.gradeLevel =
        selectedOption === 'grade' ? [selectedDevelopmentLevel] : undefined;
      newClass.maxAgeRating =
        // @ts-ignore
        selectedOption === 'age' ? parseInt(ageRating) : undefined;
      newClass.parentGroup =
        location.state?.parent.uid || ({ uid: selectedOrgUid } as IUserGroup);
    }

    if (
      newClass.maxAgeRating === undefined &&
      newClass.gradeLevel &&
      newClass.gradeLevel[0] === ''
    ) {
      setOpenPopup('required-development-level');
      return;
    }

    try {
      //class or group created or updated
      const uid = await userGroupSave(newClass);
      if (uid && !isEditing) await handleAddUser(uid, currentUser?.uid);
      const process = isEditing ? 'updated' : 'created';
      toast.success('Class/group has been successfully ' + process + '.');

      if (!hasSeenPopup) {
        setOpenPopup('signIn');
        setHasSeenPopup(true);
      }
      history.push('/analytics');
    } catch (error) {
      //toast.error('Error: ' + (error as MultiError).message);
      basePerformError(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      setIsEditing(true);
      const { title, gradeLevel, startDate, endDate } = location.state;
      setClassName(title);
      setSelectedOption(gradeLevel ? 'grade' : 'age');
      setStartAndEndDates({
        start: startDate,
        end: endDate,
      });
    }
  }, []);

  useEffect(() => {
    if (currentUser?.userGroups && orgTypeOrganizationCount === 1) {
      const [soloUserGroupOfTypeOrganization] = currentUser?.userGroups.filter(
        (g) => g.groupType === 'organization'
      );
      setSelectedOrgUid(soloUserGroupOfTypeOrganization.uid);
    }
  }, [currentUser]);

  return (
    <>
      <form>
        <div className="space-y-12 w-full">
          <div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
              <div className="col-span-full">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {!location.state
                    ? 'Create a new Class/Group'
                    : 'Edit Class/Group'}
                </h2>
                {!location.state && (
                  <>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      {orgTypeOrganizationCount && orgTypeOrganizationCount > 1
                        ? 'Fill out the info below to create a new class/group and associate it to an organization'
                        : null}

                      {orgTypeOrganizationCount === 1 ? (
                        <>
                          Fill out the info below to create a new class/group
                          and associate it to{' '}
                          <span className="underline font-semibold">
                            {
                              currentUser?.userGroups?.filter(
                                (g) => g.groupType === 'organization'
                              )[0].title
                            }
                          </span>
                        </>
                      ) : null}

                      {!orgTypeOrganizationCount
                        ? `You are not a member of an organization, ${(
                            <LinkButton to="/org-select">
                              join an org
                            </LinkButton>
                          )}`
                        : null}
                    </p>

                    {orgTypeOrganizationCount &&
                      orgTypeOrganizationCount > 1 && (
                        <OrgFinder
                          user={currentUser}
                          selectedOrg={selectedOrg}
                          setSelectedOrg={setSelectedOrg}
                          setSelectedOrgUid={setSelectedOrgUid}
                          parentComponent="create-class"
                        />
                      )}
                  </>
                )}
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="class-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Class/Group name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="class-name"
                    id="class-name"
                    autoComplete="class-name"
                    value={className}
                    className="block box-border w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-th-blue sm:text-sm sm:leading-6"
                    onChange={(e) => setClassName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-span-full">
                <fieldset className="border-0 p-0 m-0">
                  <legend className="text-sm font-semibold leading-6 text-gray-900">
                    Set Grade or Age
                  </legend>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Select a grade level or user learner age instead.
                  </p>
                  <div className="flex">
                    <div className="flex mr-10">
                      <input
                        type="radio"
                        value="grade"
                        checked={selectedOption === 'grade'}
                        onChange={handleRadioChange}
                        disabled={isEditing}
                        className={`h-4 w-4 mr-3 border-solid border-gray-300 text-th-blue focus:ring-th-blue ${
                          isEditing ? 'cursor-not-allowed' : ''
                        }`}
                      />
                      <label
                        htmlFor="grade"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Grade
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        value="age"
                        checked={selectedOption === 'age'}
                        onChange={handleRadioChange}
                        disabled={isEditing}
                        className={`h-4 w-4 mr-3 border-solid border-gray-300 text-th-blue focus:ring-th-blue ${
                          isEditing ? 'cursor-not-allowed' : ''
                        }`}
                      />
                      <label
                        htmlFor="age"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Age
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="col-span-full w-full">
                {selectedOption === 'grade' && (
                  <div className="w-44">
                    <SearchFilter
                      grades={gradeObject}
                      gradeOrder={grades}
                      selecting="Grade level"
                      handleOptionDisplay={handleSelectOptionDisplay}
                      selectedOption={selectedDevelopmentLevel}
                      isDisabled={isEditing}
                    />
                  </div>
                )}
                {selectedOption === 'age' && (
                  <div className="w-44">
                    <SearchFilter
                      handleFiltering={handleAgeFiltering}
                      ratings={ContentAgeRating}
                      selecting="Max age rating"
                      handleOptionDisplay={handleSelectOptionDisplay}
                      selectedOption={selectedDevelopmentLevel}
                      isDisabled={isEditing}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-full border-solid border-b border-r-0 border-l-0 border-t-0 border-gray-900/10 pb-12">
            <p className="text-sm font-semibold leading-6 text-gray-900 mt-0">
              Start and End dates
            </p>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Set start and end dates for your class/group. (optional)
            </p>
            <div className="flex flex-col sm:flex-row justify-between">
              <div className="mb-6 sm:mb-0">
                <div className="font-semibold pb-4">
                  Start:{' '}
                  {startAndEndDates.start && formatDate(startAndEndDates.start)}
                </div>
                <DatePicker
                  handleStartAndEndDates={handleStartAndEndDates}
                  dateType={'start'}
                />
              </div>
              <div>
                <div className="font-semibold pb-4">
                  End:{' '}
                  {startAndEndDates.end && formatDate(startAndEndDates.end)}
                </div>
                <DatePicker
                  handleStartAndEndDates={handleStartAndEndDates}
                  dateType={'end'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6 w-full pb-12">
          <button
            type="button"
            className="text-sm cursor-pointer font-semibold leading-6 text-gray-900 bg-transparent border-none p-0 outline-none"
            onClick={() => {
              if (!hasSeenPopup) {
                setOpenPopup('signIn');
                setHasSeenPopup(true);
                history.push('/analytics');
              } else {
                history.push('/analytics');
              }
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className="border-0 cursor-pointer rounded-md bg-th-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-th-blue-light focus:outline-none focus:bg-th-blue-dark"
            onClick={handleCreateClass}
          >
            Save
          </button>
        </div>
      </form>
      <TwPopup icon={<Logo />} popupName="required-development-level">
        You must set Grade or Age to create a class.
      </TwPopup>
    </>
  );
};
