import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { IUser } from '../../models/user';
import { ICurriculum } from '../../models/curriculum';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { hasMotivation, hasPDMotivation, hasPermissions, hasOtherMotivation } from '../../utils';
import { TwPopup } from '../../components/popup/tw-popup';
import { PopupContext } from '../../contexts/popup-context';
import { ReactComponent as Logo } from '../../images/logo.svg';

interface CurriculumsListProps {
  currentUser?: IUser | undefined;
  curricula?: ICurriculum[];
}

export const CurriculumsList: React.FC<CurriculumsListProps> = ({
  currentUser,
  curricula: propsCurricula,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const alwaysShowAddButton = true;
  const showAddButton =
    alwaysShowAddButton ||
    !currentUser?.userGroups?.some((g) => g.groupSubtype === 'staff');
  const sliceUpperLimit = showAddButton ? 2 : 3;
  const isMyCurriculum = pathname === '/curricula';
  const isGroupStatistics = pathname.startsWith(
    '/analytics/groups-statistics/'
  );
  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { setOpenPopup } = popupContext;

  const isMemberOfOrg = currentUser?.userGroups?.some(
    (g) => g.groupType === 'organization'
  );

  const handlePopupDisplay = () => {
    if (isMemberOfOrg) {
      setOpenPopup('add-curriculum');
    } else {
      setOpenPopup('orgSelectBeforeCurriculumAdd');
    }
  };

  const collectUniqueCurriculums = (user: IUser | undefined): ICurriculum[] => {
    const curriculumSet: { [key: number]: ICurriculum } = {};
    user?.userGroups?.forEach((userGroup) => {
      userGroup.curriculums?.forEach((curriculum) => {
        if (curriculum.available) {
          curriculumSet[curriculum.uid] = curriculum;
        }
      });
    });

    return Object.values(curriculumSet);
  };

  const curricula = propsCurricula ?? collectUniqueCurriculums(currentUser);

  return (
    <>
      <div className="flex items-center justify-between content-baseline">
        <h3>{isMyCurriculum ? 'My Curricula' : 'Curricula'}</h3>
        {!isMyCurriculum && (
          <Link to="/curricula" className="no-underline text-th-blue -mb-2">
            See all
          </Link>
        )}
      </div>
      <div className="border-solid border border-thin-gray grid grid-cols-1 sm:grid-cols-3 gap-4 rounded-lg p-4 mb-6">
        {(!isMyCurriculum
          ? curricula.slice(0, sliceUpperLimit)
          : curricula
        ).map((c) => (
          <div className="relative" key={c.uid}>
            <Link
              key={c.uid}
              className="no-underline"
              to={`/curriculum/${c.uid}`}
            >
              <div className="rounded-lg p-4 h-28 border-solid border border-th-blue text-th-blue">
                <div className="flex w-full justify-between">
                  <div className="text-lg text-th-blue mb-2 leading-tight">
                    {c.name}
                  </div>
                </div>

                <div className="flex items-center">
                  {c.grades ? `Grade level: ${c.grades}` : null}
                  {c.minAgeRating ? `Rec. age: ${c.minAgeRating}+` : null}
                </div>
              </div>
            </Link>
          </div>
        ))}
        {((!isMemberOfOrg &&
          (hasMotivation(currentUser?.motivation) ||
            hasPDMotivation(currentUser?.motivation) ||
            hasOtherMotivation(currentUser?.motivation))) ||
          hasPermissions(currentUser?.userGroups)) &&
          showAddButton && (
            <div
              onClick={() => handlePopupDisplay()}
              className="h-28 p-4 rounded-lg flex items-center justify-center border-solid border border-th-blue cursor-pointer"
            >
              <p className="text-th-blue">+ Add Curriculum</p>
            </div>
          )}
        {!curricula.length && (
          <div className="flex flex-col col-span-full">
            <div>No curricula to show.</div>
            {!isGroupStatistics && (
              <div className="text-sm">
                {isMemberOfOrg ? (
                  <>
                    <Link to="/group-select">Add</Link> a class or group to have
                    access to a curriculum.
                  </>
                ) : (
                  <>
                    <Link to="/org-select">Select</Link> an organization or{' '}
                    <Link to="invite-code">enter an invite code</Link> to get
                    access to a curriculum.
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <TwPopup
        icon={<Logo />}
        popupName="add-curriculum"
        actionButton={
          <button
            type="button"
            className="border-0 cursor-pointer mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-2 bg-th-blue"
            onClick={() => {
              history.push('/my-classes/create');
              setOpenPopup(null);
            }}
          >
            Create New Class
          </button>
        }
      >
        To add a curriculum, create a new class for a given age or grade level.
      </TwPopup>
      <TwPopup
        popupName="orgSelectBeforeCurriculumAdd"
        icon={<Logo />}
        actionButton={
          <button
            type="button"
            className="border-0 cursor-pointer mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-2 bg-th-blue"
            onClick={() => {
              history.push('/org-select');
              setOpenPopup(null);
            }}
          >
            Go
          </button>
        }
      >
        <div className="text-sm text-gray-500 text-center">
          To add a new curriculum (e.g. for grades Pre-K to 12) please choose or create
          an organization.
        </div>
      </TwPopup>
    </>
  );
};
