import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../models/user';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';
import { removeFields } from '../../helpers/common-helpers';

const QUERY = gql`
    query {
        user {
            email,
            confirmedEmail: confirmed_email
            uid,
            role,
            firstName: first_name,
            lastName: last_name,
            birthdate,
            configurationFinished: configuration_finished,
            disabled,
            motivation
            numInvitesAllowed: num_invites_allowed,
            hasCurricula: has_curricula,
            canEarnPips: can_earn_pips,
            extensionInstalled: extension_installed,
            subscriptionStatus: subscription_status,
            subscriptionExpiration: subscription_expiration,
            pipsEarned: pips_earned,
            encryptedUid: encrypted_uid,
            completedCertifications: completed_certifications {
                uid,
                name,
                description,
                achievementType: achievement_type,
                iconFileName: icon_file_name,
                index,
                dateAchieved: date_achieved
            },
            availableCertifications: available_curriculum_certifications {
                uid,
                completionInfo: completion_info,
                achievement {
                    uid
                }
            },

            userPlan: user_plan {
                uid,
                name,
                type,
                accessType: access_type,
                free,
                type,
                sector,
                trialActiveForUser: trial_active_for_user,
                trialExpiredForUser: trial_expired_for_user,
                trialExpirationForUser: trial_expiration_date_for_user,
                certifications: included_certifications {
                    uid,
                    name,
                    description,
                    achievementType: achievement_type,
                    iconFileName: icon_file_name,
                    index,
                    available,
                }
            }

            userGroups: user_groups {
                uid,
                title,
                default,
                unlisted,
                userCount: user_count,
                userPermissions: user_permissions,
                enabled,
                emailConfirmationRequired: email_confirmation_required
                groupType: group_type,
                groupSubtype: group_subtype,
                expired,
                startDate: start_date,
                endDate: end_date,
                gradeLevel: grade_level,
                maxAgeRating: max_age_rating

                profile {
                    uid,
                    name
                }

                owner {
                    uid,
                    email,
                    firstName: first_name,
                    lastName: last_name,
                    role
                },  

                curriculums {
                    uid,
                    name,
                    description,
                    available,
                    grades,
                    minAgeRating: min_age_rating
                }

                children {
                  uid,
                  title,
                  enabled,
                  expired,
                  unlisted,
                  group_subtype,
                  owner {
                    uid,
                    firstName: first_name,
                    lastName: last_name
                    role 
                  }
                }

                parent {
                  uid,
                  title,
                  groupType: group_type

                  owner {
                    uid
                    role
                  }
                }
            }

            narratives {
                uid,
            },

            characters {
                uid,
            },

            completedUnits: completed_units {
                uid,
            }
        }
    }
`;

export async function getCurrentUser(): Promise<IUser> {
    const result: FetchResult<{ user: IUser }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.user, ['__typename']);
}
